import React, { useEffect, useRef } from "react";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import classes from "./Scroll.module.css";
import { Mousewheel, Autoplay ,FreeMode} from "swiper/modules";

SwiperCore.use([Mousewheel, Autoplay]);

const Scroll = () => {
  const swiperRef = useRef(null);
  useEffect(() => {
    const swiperInstance = new SwiperCore(swiperRef.current, {
      slidesPerView: 4,
      modules:[Autoplay],
      loop:true,
      spaceBetween: 0,
      autoplay:{
        delay:700,
        disableOnInteraction:false,
        reverseDirection:false
      }
    });

    return () => {
      swiperInstance.destroy(true, true);
    };
  }, []);
  return (
    <>
      <div className="swiper-container" ref={swiperRef}>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Scroll;
