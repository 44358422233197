import React from "react";
import classes from "./MainCarousel.module.css";
import OwlCarousel from "react-owl-carousel";

const MainCarousel = ({ data,primary,secondary }) => {
     //Owl Carousel Settings
  const options = {
    loop: true,
    items: 3,
    margin: 0,
    margin: 20,
    center: true, // Center the active item
    // autoWidth: true, // Adjust item width automatically
    nav: false,
    autoplay: true,
    dots: true,
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    autoplayHoverPause: true,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const navigateToGame = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <div className={classes.carousel_title}>
        <h1 className={classes.carousel_title_heading}>
          {primary}
          <span className={classes.carousel_title_small_heading}> {secondary}</span>
        </h1>
      </div>
      <div className={classes.game_carousel_container}>
        <div className={classes.game_carousel_sub_container}>
          <OwlCarousel className="owl-theme" {...options}>
            {data?.map((dataItem, i) => {
              if (dataItem?.id != 19 || dataItem?.id != "19") {
                return (
                  <div
                    onClick={() => navigateToGame(dataItem?.gameUrl)}
                    className={classes.carousel_container}
                    key={i}
                  >
                    <div className={classes.game_image}>
                      <img
                        className={classes.game_image_img}
                        src={dataItem?.imageUrl}
                        alt={dataItem?.gameName}
                      />
                    </div>
                    <h3 className={classes.text}>{dataItem?.gameName}</h3>
                  </div>
                );
              }
            })}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default MainCarousel;
