import React from "react";
import classes from "./Drawer.module.css";
import { useLocation } from "react-router-dom";

const Drawer = ({showMenu,closeShowMenu}) => {
  const location=useLocation();
  const closeMenu=()=>{
    closeShowMenu();
  }
  return (
      <div className={`${classes.drawer} ${showMenu && classes.active}`}>
        <div className={classes.logo_container}>
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className={classes.logo}
          />
        </div>
        <div className={classes.categories}>
          <a
            className={`${classes.link} ${
              location?.hash == "#popular" && classes.active
            }`}
            href="#popular"
            onClick={closeMenu}
          >
            <p className={classes.link_text}>Popular Games</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#action" && classes.active
            }`}
            onClick={closeMenu}
            href="#action"
          >
            <p className={classes.link_text}>Action</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#puzzle" && classes.active
            }`}
            onClick={closeMenu}
            href="#puzzle"
          >
            <p className={classes.link_text}>Puzzle</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#adventure" && classes.active
            }`}
            onClick={closeMenu}
            href="#adventure"
          >
            <p className={classes.link_text}>Adventure</p>
          </a>
        </div>
      </div>
  );
};

export default Drawer;
