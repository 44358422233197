import React from "react";
import classes from "./Layout.module.css";

const Layout = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.top_side_image}>
        <img
          src="/assets/images/big_rocket.png"
          alt="blur"
          className={classes.top_image}
        />
      </div>
      <div className={classes.left_side_image}>
        <img
          src="/assets/images/blur_circle.png"
          alt="blur"
          className={classes.left_image}
        />
      </div>
      <div className={classes.right_side_image}>
        <img
          src="/assets/images/blur_circle_2.png"
          alt="blur"
          className={classes.right_image}
        />
      </div>
      {children}
    </div>
  );
};

export default Layout;
