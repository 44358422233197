import React from "react";
import { useLocation, useRoutes } from "react-router-dom";
import HomePage2 from "../pages/HomePage2";
import { AnimatePresence, motion } from "framer-motion";
import LoginPage from "../pages/LoginPage";
import IntroPage from "../pages/IntroPage";
import HomePage from "../pages/HomePage";
import HomePage4 from "../pages/HomePage4";

const Routing = () => {
  const routes = [
    {
      path: "/",
      element: <IntroPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    // {
    //   path: "/home2",
    //   element: <HomePage2 />,
    // },
    {
      path: "/home",
      element: <HomePage4 />,
    },
  ];

  const element = useRoutes(routes);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {element}
      <motion.div
        initial={{ scaleX: 1 }}
        key={location.pathname}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: 1 }}
        className="privacy-screen"
      />
    </AnimatePresence>
  );
};

export default Routing;
