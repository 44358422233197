import React, { useEffect } from "react";
import Layout from "../components/Layout";
import classes from "./IntroPage.module.css";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const IntroPage = () => {
  const navigate = useNavigate();

    useEffect(() => {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }, []);
  return (
    <Layout>
      <div className={classes.container}>
        {/* <video autoplay loop muted controls>
        <source src="/assets/videos/introVideo.mp4" type="video/mp4" />
      </video> */}

        <ReactPlayer
          url={`/assets/videos/newVideo.mp4`}
          muted
          className={classes.video}
          playing={true}
          loop={true}
        />

        <div className={classes.text_box}>
          <h1>Selamat datang di </h1>
          <h1>Bubbly Gamez</h1>
          <p>Loading....</p>
        </div>
      </div>
    </Layout>
  );
};

export default IntroPage;
