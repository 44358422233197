import React, { useState } from "react";
import classes from "./LoginPage.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import { loginApi, loginApi2 } from "../api/api";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";

const LoginPage = () => {
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const domain = window.location.href;

  const submitHandler1 = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = { PIN: number };
      const res = await axios.post(loginApi, data);
      setLoading(false);
      console.log(res, "res");
      if (
        res?.data?.result == "User Login Successfully" ||
        res?.status == 200
      ) {
        localStorage.setItem("pin", JSON.stringify(number));
        navigate("/home");
      } else {
        toast.error("Something Went Wrong!");
      }
    } catch (error) {
      localStorage.removeItem("pin");
      setLoading(false);
      console.log(error, "error");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const submitHandler2 = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.get(`${loginApi2}?PIN=${number}`);
      setLoading(false);
      console.log(res, "res");
      if (res?.status == 200) {
        localStorage.setItem("pin", JSON.stringify(number));
        navigate("/home");
      } else {
        toast.error("Something Went Wrong!");
      }
    } catch (error) {
      localStorage.removeItem("pin");
      setLoading(false);
      console.log(error, "error");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <Layout>
      <div className={classes.sub_container}>
        <div className={classes.title_container}>
          <h3 className={classes.title}>Selamat datang di Bubbly Gamez!</h3>
          <div className={classes.text_container}>
            <p className={classes.text}>Login to play exciting games!</p>
            <img
              src="/assets/images/rocket.png"
              alt="rocket"
              className={classes.rocket_img}
            />
          </div>

          <div className={classes.grid}>
            <div className={classes.image}>
              <img src="/assets/images/login/download.webp" alt="games" />
            </div>
            <div className={classes.image}>
              <img src="/assets/images/login/download1.webp" alt="games" />
            </div>
            <div className={classes.image}>
              <img src="/assets/images/login/download2.webp" alt="games" />
            </div>
          </div>
        </div>
        <div className={classes.form_container}>
          <form
            className={classes.form}
            onSubmit={
              domain == "https://bubblygamez.gameit.in/login"
                ? submitHandler1
                : submitHandler2
            }
          >
            <h3 className={classes.form_title}>Masukkan PIN Anda!</h3>
            <div className={classes.form_container}>
              <div className={classes.input_group}>
                <input
                  type="password"
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  autoFocus={true}
                  placeholder="******"
                  className={classes.input}
                />
                <i className={`fa-solid fa-lock ${classes.icon}`}></i>
              </div>
              <button type="submit" className={classes.btn}>
                Lanjut
              </button>
              {loading && (
                <div className={classes.center_loader}>
                  <span className={classes.loader}></span>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
