import React, { useEffect, useState } from "react";
import classes from "./HomePage4.module.css";
import Layout from "../components/Layout";
import ReactPlayer from "react-player";
import axios from "axios";
import { gamesApi, loginApi, loginApi2 } from "../api/api";
import MainCarousel from "../components/MainCarousel";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "../components/Drawer";
import { toast } from "react-toastify";

const HomePage4 = () => {
  const [loading, setLoading] = useState(true);
  const [catAction, setCatAction] = useState([]);
  const [catPuzzle, setCatPuzzle] = useState([]);
  const [catAdventure, setCatAdventure] = useState([]);
  const [catPopular, setCatPopular] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  const domain = window.location.href;
  const cleanUrl = domain.split("#")[0];
  console.log(cleanUrl, "domain");

  const navigate = useNavigate();

  const checkPin1 = async () => {
    try {
      const number = JSON.parse(localStorage.getItem("pin"));
      const data = { PIN: number };
      const res = await axios.post(loginApi, data);
      if (
        res?.data?.result == "User Login Successfully" ||
        res?.status == 200
      ) {
        localStorage.setItem("pin", JSON.stringify(number));
      } else {
        localStorage.removeItem("pin");
        navigate("/");
      }
    } catch (error) {
      localStorage.removeItem("pin");
      navigate("/");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const checkPin2 = async () => {
    try {
      const number = JSON.parse(localStorage.getItem("pin"));
      const res = await axios.get(`${loginApi2}?PIN=${number}`);
      if (res?.status == 200) {
        localStorage.setItem("pin", JSON.stringify(number));
      } else {
        toast.error("Something Went Wrong!");
        localStorage.removeItem("pin");
        navigate("/");
      }
    } catch (error) {
      localStorage.removeItem("pin");
      navigate("/");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(gamesApi);
      setCatAction(() =>
        res?.data?.result.filter((data) => data?.categoryEnglish == "Action")
      );
      setCatPuzzle(() =>
        res?.data?.result.filter((data) => data?.categoryEnglish == "Puzzle")
      );
      setCatAdventure(() =>
        res?.data?.result.filter((data) => data?.categoryEnglish == "Adventure")
      );
      const shuffledData = res?.data?.result.sort(() => Math.random() - 0.5);
      const popularGames = shuffledData.slice(0, 7);
      setCatPopular(popularGames);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (cleanUrl == "https://bubblygamez.gameit.in/home") {
      checkPin1();
    } else {
      checkPin2();
    }
    fetchDataFromBackend();
  }, []);

  const navigateToGame = (url) => {
    window.location.href = url;
  };

  const closeShowMenu = () => {
    setShowMenu(false);
  };

  return (
    <Layout>
      <Drawer showMenu={showMenu} closeShowMenu={closeShowMenu} />
      {/* NAVBAR SECTION.... */}
      <div className={classes.navbar}>
        <div className={classes.logo_container}>
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className={classes.logo}
          />
        </div>
        <div className={classes.categories}>
          <a
            className={`${classes.link} ${
              location?.hash == "#popular" && classes.active
            }`}
            href="#popular"
          >
            <p className={classes.link_text}>Popular Games</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#action" && classes.active
            }`}
            href="#action"
          >
            <p className={classes.link_text}>Action</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#puzzle" && classes.active
            }`}
            href="#puzzle"
          >
            <p className={classes.link_text}>Puzzle</p>
          </a>
          <a
            className={`${classes.link} ${
              location?.hash == "#adventure" && classes.active
            }`}
            href="#adventure"
          >
            <p className={classes.link_text}>Adventure</p>
          </a>
        </div>
        <div className={classes.menu_icon}>
          <label for="check">
            <input
              type="checkbox"
              id="check"
              checked={showMenu}
              onClick={() => setShowMenu((prevValue) => !prevValue)}
            />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
      </div>
      {/* HEAD SECTION */}

      <div className={classes.container}>
        <ReactPlayer
          url={`/assets/videos/newVideo.mp4`}
          muted
          className={classes.video}
          playing={true}
          loop={true}
        />
        <div className={classes.text_box}>
          <h1>Selamat datang di </h1>
          <h1 className={classes.text_color}>Bubbly Gamez</h1>
          <p className={classes.text_small}>
            FIND NEW GAMES BASED ON NEW TECHNOLOGY WHICH ARE FULL WITH FUN,
            ENTERTAINMENT AND KNOWLEDGE.
          </p>
          <a href="#popular" className={classes.play_btn}>
            Play Games
          </a>
        </div>
      </div>

      {loading ? (
        <div className={classes.center_loader}>
          <span className={classes.loader}></span>
        </div>
      ) : (
        <>
          <div className={classes.games_container}>
            {/* SECTION POPULAR GAMES  */}
            <div id="popular" className={classes.section_popular_games}>
              <MainCarousel
                data={catPopular}
                primary="Popular"
                secondary="Games"
              />
            </div>

            <div id="action" className={classes.section_action_games}>
              <MainCarousel
                data={catAction}
                primary="Action"
                secondary="Games"
              />
            </div>

            <Layout>
              <div className={classes.top_container}>
                <ReactPlayer
                  url={`/assets/videos/newVideo.mp4`}
                  muted
                  className={classes.video_2}
                  playing={true}
                  loop={true}
                />
                <div className={classes.text_box_2}>
                  <h1 className={classes.text_color}>Top 3 Games</h1>
                  <div className={classes.items_container}>
                    <div className={classes.items}>
                      {catPopular?.slice(0, 3).map((dataItem, i) => {
                        if (dataItem?.id != 19 || dataItem?.id != "19") {
                          return (
                            <div className={classes.top_games_container}>
                              <div className={classes.number_image_container}>
                                <img
                                  src={`/assets/images/numbers/${
                                    i == 0
                                      ? "1.png"
                                      : i == 1
                                      ? "2.png"
                                      : "3.png"
                                  }`}
                                  style={{
                                    marginLeft:
                                      i == 1 ? "3rem" : i == 2 ? "6rem" : 0,
                                  }}
                                  alt="number"
                                  className={classes.number_image}
                                />
                              </div>
                              <div
                                onClick={() =>
                                  navigateToGame(dataItem?.gameUrl)
                                }
                                className={classes.top_games}
                                style={{
                                  marginLeft:
                                    i == 1 ? "3rem" : i == 2 ? "6rem" : 0,
                                }}
                                key={i}
                              >
                                <div className={classes.image}>
                                  <img
                                    src={dataItem?.imageUrl}
                                    alt={dataItem?.gameName}
                                  />
                                </div>
                                <h3 className={classes.text}>
                                  {dataItem?.gameName}
                                </h3>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div id="puzzle" className={classes.section_puzzle_games}>
                <MainCarousel
                  data={catPuzzle}
                  primary="Puzzle"
                  secondary="Games"
                />
              </div>

              <div id="adventure" className={classes.section_action_games}>
                <MainCarousel
                  data={catAdventure}
                  primary="Adventure"
                  secondary="Games"
                />
              </div>
            </Layout>
          </div>
        </>
      )}
    </Layout>
  );
};

export default HomePage4;
